import styled from 'styled-components';
import Link from 'next/link';

import { Container } from '@yoweb/ui/components/containers';
import { getSpace } from '@yoweb/ui/styles/utils/theme';
import { MediaHero, type MediaHeroProps } from './MediaHero';
import { TextHeader, type TextHeaderProps } from './TextHeader';
import { Box } from '@yoweb/ui/components/Box';
import AnimateIn, {
  InView,
  STAGGER_DELAY,
  EASINGS,
  DURATIONS,
} from '@yoweb/ui/components/AnimateIn';
import { Button } from '@yoweb/ui/components/buttons/Button';

export type MediaHeaderProps = {
  ctaHref?: string;
  ctaLabel?: string;
  ctaAboveMedia?: boolean;
} & Omit<TextHeaderProps, 'isInView'> &
  MediaHeroProps &
  DataTestId;

/**
 * Component that renders TextHeader and MediaHero in a grid.
 */
export const MediaHeader = ({
  image,
  imageAltText,
  subtitle,
  title,
  videoLabel,
  videoUrl,
  videoLoop,
  videoLoopMobile,
  mobilePosition = 'center',
  ctaHref,
  ctaLabel,
  ctaAboveMedia,
  ...props
}: MediaHeaderProps) => (
  <InView>
    {(isInView) => (
      <HeroContainer data-testid={props['data-testid']}>
        <TextHeader title={title} subtitle={subtitle} isInView={isInView} />
        <AnimateIn
          isVisible={isInView}
          config={{
            y: { offset: 100, duration: DURATIONS.d67 },
            opacity: { animate: true, ease: EASINGS.ease33 },
          }}
          delay={STAGGER_DELAY + DURATIONS.d67}
        >
          {ctaAboveMedia && ctaHref && ctaLabel && (
            <Box display="flex" justifyContent="center" alignItems="center" mt={'normal3'}>
              <Box maxWidth={170}>
                <Link href={ctaHref} passHref legacyBehavior>
                  <Button size="lg" as="a">
                    {ctaLabel}
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
          <Box pt={{ _: 40, lg: 'medium3' }}>
            <MediaHero
              image={image}
              videoUrl={videoUrl}
              videoLabel={videoLabel}
              imageAltText={imageAltText}
              videoLoop={videoLoop}
              videoLoopMobile={videoLoopMobile}
              mobilePosition={mobilePosition}
            />
          </Box>
          {!ctaAboveMedia && ctaHref && ctaLabel && (
            <Box display="flex" justifyContent="center" alignItems="center" mt={'normal3'}>
              <Box maxWidth={170}>
                <Link href={ctaHref} passHref legacyBehavior>
                  <Button size="lg" as="a">
                    {ctaLabel}
                  </Button>
                </Link>
              </Box>
            </Box>
          )}
        </AnimateIn>
      </HeroContainer>
    )}
  </InView>
);

const HeroContainer = styled(Container)`
  && {
    padding-top: ${getSpace('medium1')};
  }
`;
