import { PrismicRichText } from '@prismicio/react';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type MediaHeaderSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
} from './MediaHeaderSliceTypes';
import { MediaHeader } from '@yoweb/ui/components/marketing/MediaHeader';
import { mediaHeaderSerializer } from '@yoweb/prismic-slicemachine/utils/serializers';

type MediaHeaderSliceProps = {
  slice: MediaHeaderSliceResponse;
  mobilePosition?: string;
} & DataTestId;

const MediaHeaderSlice = ({ slice, ...rest }: MediaHeaderSliceProps) => {
  const {
    title,
    subtitle,
    image,
    videoLabel,
    videoLoop,
    videoLoopMobile,
    videoUrl,
    ctaHref,
    ctaLabel,
    ctaAboveMedia,
  } = slice.primary;

  const props = {
    title: title ? <PrismicRichText field={title} components={mediaHeaderSerializer} /> : '',
    subtitle,
    image: image?.url,
    imageAltText: image?.alt ?? '',
    videoLabel,
    videoLoop: videoLoop?.url ?? undefined,
    videoLoopMobile: videoLoopMobile?.url ?? undefined,
    videoUrl: videoUrl?.url ?? undefined,
    ctaHref,
    ctaLabel,
    ctaAboveMedia,
  };

  return <MediaHeader {...props} {...rest} />;
};

export default withPrismicGuardian(MediaHeaderSlice, {
  primaryRequired: requiredPrimaryFields,
  itemsRequired: requiredItemsFields,
});
