import { PrismicRichText } from '@prismicio/react';
import * as prismicHelpers from '@prismicio/helpers';
import type { ImageProps } from 'next/image';

import { withPrismicGuardian } from '@yoweb/prismic-slicemachine/components/withPrismicGuardian';
import {
  type VerticalScrollCarouselSliceResponse,
  requiredPrimaryFields,
  requiredItemsFields,
  requiredPrimaryFieldsWithTitle,
} from './VerticalScrollCarouselSliceTypes';
import { blurParam } from '@yoweb/prismic-slicemachine/slices/slices.utils';
import { VerticalScrollCarousel } from '@yoweb/ui/components/sections';
import { isObjectEmpty } from '@yoweb/utils/isObjectEmpty';
import {
  inverseLabelSerializer,
  subtitleSerializer,
} from '@yoweb/prismic-slicemachine/utils/serializers';
import { Box } from '@yoweb/ui/components/Box';
import { Title } from '@yoweb/ui/components/typography/Title';
import type { VerticalScrollCarouselProps } from '@yoweb/ui/components/sections/VerticalScrollCarousel';
import { Container } from '@yoweb/ui/components/containers';

type VerticalScrollCarouselSliceProps = {
  slice: VerticalScrollCarouselSliceResponse;
} & Omit<VerticalScrollCarouselProps, 'ctaHref' | 'ctaLabel' | 'items'>;

const VerticalScrollCarouselSlice = ({ slice, ...rest }: VerticalScrollCarouselSliceProps) => {
  const { ctaHref, ctaLabel, useWhitePaperCta, buttonVariant, whitePaperModal, openModal, navId } =
    slice.primary;

  const props = {
    ctaHref,
    ctaLabel,
    items:
      slice.items
        // Replace all null values with undefined
        ?.map(({ image, mobileImage, quote, subtitle, title, initials, label }) => ({
          image: {
            src: (image?.url ?? '') as ImageProps['src'],
            alt: image?.alt ?? '',
            blurDataURL: `${image?.url}&${blurParam}`,
          },
          ...(!isObjectEmpty(mobileImage as Record<string, unknown>)
            ? {
                mobileImage: {
                  src: (mobileImage?.url ?? '') as ImageProps['src'],
                  alt: mobileImage?.alt ?? '',
                  blurDataURL: `${mobileImage?.url}&${blurParam}`,
                },
              }
            : {}),
          title,
          subtitle: <PrismicRichText field={subtitle} components={subtitleSerializer} />,
          initials,
          label: label ? (
            <PrismicRichText field={label} components={inverseLabelSerializer} />
          ) : undefined,
          quote,
        })) ?? [],
    buttonVariant,
    clickHandler: useWhitePaperCta ? openModal : undefined,
  };

  if (slice.variation === 'withTitle') {
    return (
      <Box
        id={navId}
        mt={{ _: 'xl1', lg: 168 }}
        maxWidth="100vw"
        data-testid="vertical-carousel-section"
      >
        <Container>
          <Box mb="medium2">
            <Title
              textAlign="center"
              size={{ _: 'lg', lg: 'xl' }}
              data-testid="vertical-carousel-title"
            >
              {slice.primary.title}
            </Title>
            {slice.primary?.subtitle && (
              <Box mt="normal1">
                <Title textAlign="center" variant="muted" size="xs" weight="regular">
                  {prismicHelpers.asText(slice.primary.subtitle)}
                </Title>
              </Box>
            )}
          </Box>
        </Container>
        <VerticalScrollCarousel
          data-testid="vertical-scroll-carousel"
          testIdPrefix="with-title"
          {...props}
          {...rest}
        />
        {whitePaperModal}
      </Box>
    );
  }

  return (
    <div id={navId}>
      <VerticalScrollCarousel
        data-testid="vertical-scroll-carousel"
        testIdPrefix="default"
        {...props}
        {...rest}
      />
    </div>
  );
};

export default withPrismicGuardian(VerticalScrollCarouselSlice, {
  type: 'variations',
  variations: {
    'default-slice': {
      primaryRequired: requiredPrimaryFields,
      itemsRequired: requiredItemsFields,
    },
    withTitle: {
      primaryRequired: requiredPrimaryFieldsWithTitle,
      itemsRequired: requiredItemsFields,
    },
  },
});
